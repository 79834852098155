import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AtomButton } from '../../atoms/AtomButton'
import { ContainerFluid } from '../../commons/Container'
import { Grid } from '../../commons/Grid'
import { Colors } from '../../commons/Theme'
import {
  getCookie,
  getPrivacyValue,
  getYYYYMMDD,
  isValidEmail,
  isValidTelephone,
  removeTrailingSlashes,
} from '../../commons/utils'
import {
  StyledMessaggioText,
  StyledWebformContainer,
  Text,
} from '../Webform/Webform.style'
import { CellulariPrivacyModal } from './components/CellulariPrivacyModal'
import { ConsensoSection } from './components/ConsensoSection'
import { SimpleHeader } from 'components/Header/SimpleHeader'
import { ThankYouPage } from './components/ThankYouPage'
import {
  StyledPostazioneInput,
  StyledPostazioneLabel,
  StyledPostazioneRicarica,
  StyledRicaricaMessaggioTitle,
  StyledSubmitButton,
} from './RicaricaForm.style'
import { InputRadio } from 'commons/FormComponents/InputRadio'
import { Input } from 'commons/FormComponents/Input'
import { RadioLabel } from 'commons/FormComponents/InputRadio.style'

export const RicaricaForm = () => {
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [postazione, setPostazione] = useState<string>('')

  const [maggiorenne, setMaggiorenne] = useState('')
  const [promozioni, setPromozioni] = useState('')
  const [analisi, setAnalisi] = useState('')
  const [promozioni2, setPromozioni2] = useState('')
  const [manleva, setManleva] = useState('')

  const [isFormValid, setIsFormValid] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [error, setError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const [showModal, setShowModal] = useState(false)

  async function handleOnSubmit() {
    setDisabledButton(true)

    const L1 = getPrivacyValue(promozioni)
    const L2 = getPrivacyValue(analisi)
    const L3 = getPrivacyValue(promozioni2)

    const date = new Date()

    const formattedDate = getYYYYMMDD(date)
    const hour = date.toLocaleTimeString()

    const iubendaCookie = getCookie('_ga')

    const IP = await fetch('https://api.ipify.org/?format=json')
      .then((res) => res.json())
      .then((res) => res.ip)
      .catch(() => {
        return 'ipnonrecuperato'
      })

    fetch(
      `${removeTrailingSlashes(
        process.env.A2A_DRUPAL_CASA_URL
      )}/a2a-api/create-postazione-ricarica`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXNzd29yZCI6InojT2RsRTFKU3V4eXUmdVhlUl4mQTlkbm1vRkohaHVtbDNqVlRBOFRIY1kmeW9ASkhYIn0.riPIcy0C_FUY3XfzaIyxepkVITGwWxD71eC4bRVj6j4`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          auth: 'aDv1C3',
          FirstName: name,
          LastName: surname,
          MobilePhone: phone,
          Phone: '',
          Email: email,
          FiscalCode: '',
          InterestPrimaryProduct: '',
          InterestSecondaryProduct: '',
          ActionType: 'Target',
          LeadSource: 'QRCode',
          LeadSourceDetail: 'JBP',
          SubjectType: 'HOU',
          RecordTypeId: 'Residenziale',
          Status: 'Nuovo',
          LeadType: 'Slave',
          DWHReady: 'SI',
          EntryMode: 'List Import',
          CollectionDate: formattedDate,
          CollectionContext: 'AL',
          TimeStampImp: `${formattedDate} ${hour}`,
          AddressIP: IP,
          PrivacyVersioning: 'V5',
          ConsentL0: '',
          PhoneConsentL1VoiceWithOp: '',
          PhoneConsentL1VoiceWoutOp: '',
          CountryCodeMob1: 'IT',
          MobileConsentL1Text: L1,
          MobileConsentL1VoiceWithOp: L1,
          MobileConsentL1VoiceWoutOp: L1,
          ConsentL1Email: L1,
          DeviceId: iubendaCookie,
          Description: 'JBP_ricarica_cellulari',
          CreatedText: formattedDate,
          L1Address: '',
          L2: L2,
          L3Address: '',
          L3Email: L3,
          L3Sms: L3,
          L3PhoneOp: '',
          L3PhoneNoOp: '',
          L3MobileOp: L3,
          L3MobileNoOp: L3,
          evento: 'JBP_ricarica_cellulari',
          privacy_manleva: 'OK',
          numero_postazione: postazione,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setSubmitted(true)
        } else {
          setError('Qualcosa é andato storto.')
        }
      })
      .catch((err) => {
        setError('Qualcosa é andato storto.')
        console.error(err)
      })
      .finally(() => {
        setDisabledButton(false)
      })
  }

  useEffect(() => {
    if (
      name &&
      surname &&
      email &&
      phone &&
      postazione &&
      maggiorenne &&
      manleva
    ) {
      if (isValidEmail(email) && isValidTelephone(phone)) {
        setIsFormValid(true)
        return
      }
      setIsFormValid(false)
      return
    }
    setIsFormValid(false)
  }, [name, surname, email, phone, maggiorenne, manleva, postazione])

  if (submitted) {
    return <ThankYouPage />
  }

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <SimpleHeader />
      <StyledWebformContainer>
        <ContainerFluid>
          <Grid
            cols={1}
            desktopCols="1fr 2fr"
            colsLargeGap="100px"
            rowsGap="40px"
          >
            <RicaricaMessaggio />
            <div>
              <Grid
                cols={1}
                tabletCols={2}
                rowsGap="40px"
                colsDesktopGap="28px"
              >
                <Input
                  label="Nome"
                  value={name}
                  onChange={(e) => setName(e)}
                  placeholder="Nome"
                  required={true}
                />
                <Input
                  label="Cognome"
                  value={surname}
                  onChange={(e) => setSurname(e)}
                  placeholder="Cognome"
                  required={true}
                />
                <Input
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e)}
                  placeholder="Email"
                  required={true}
                  type="email"
                />
                <Input
                  label="Cellulare"
                  value={phone}
                  onChange={(e) => setPhone(e)}
                  placeholder="Cellulare"
                  required={true}
                  type="tel"
                />
              </Grid>
              <InputRadio
                name="privacy"
                value="maggiorenne-yes"
                style={{ margin: '40px 0' }}
                onChange={(e) => setMaggiorenne(e.target.value)}
              >
                Dichiaro di essere maggiorenne, di aver preso visione dell'
                <a onClick={() => setShowModal(true)}>
                  informativa privacy
                </a>{' '}
                sul trattamento dei dati. *
              </InputRadio>
              <RadioLabel style={{ marginBottom: '40px' }}>
                Presa visione dell'informativa privacy sopra indicata fornita ai
                sensi della vigente normativa in materia di Privacy autorizzo
                A2A Energia a:
              </RadioLabel>
              <ConsensoSection
                message="Inviarmi promozioni e comunicazioni commerciali di propri prodotti e servizi, ed effettuare ricerche di mercato tramite modalità di contatto automatizzate e tradizionali"
                name="promozioni"
                onChange={(e) => setPromozioni(e.target.value)}
              />
              <ConsensoSection
                message="Effettuare l'analisi delle mie abitudini e scelte di consumo per ricevere offerte e promozioni sempre più in linea con le mie esigenze"
                name="analisi"
                onChange={(e) => setAnalisi(e.target.value)}
              />
              <ConsensoSection
                message="Inviarmi promozioni e comunicazioni commerciali di prodotti e servizi di società terze tramite modalità di contatto automatizzate e tradizionali"
                name="promozioni2"
                onChange={(e) => setPromozioni2(e.target.value)}
              />
              <InputRadio
                label="Prendo atto e accetto che il servizio gratuito di ricarica di telefoni cellulari viene fornito senza alcuna garanzia e che il fornitore dello stesso non potrà essere ritenuto responsabile di eventuali danni ai dispositivi che non siano diretta ed esclusiva responsabilità del fornitore stesso *"
                name="manleva"
                value="manleva-yes"
                onChange={(e) => setManleva(e.target.value)}
              />
              <Text
                style={{ marginTop: '40px' }}
                $size={12}
                $color={Colors.grey700}
                $align="right"
              >
                *Campi obbligatori
              </Text>
              <PostazioneRicarica
                value={postazione}
                onChange={(value: string) => setPostazione(value)}
              />
              <StyledSubmitButton>
                <AtomButton
                  label="Salva 🚀"
                  disabled={!isFormValid || disabledButton}
                  onClick={handleOnSubmit}
                />
              </StyledSubmitButton>
              {error && (
                <Text
                  style={{ marginTop: '8px' }}
                  $size={14}
                  $color={Colors.error}
                  $align="right"
                >
                  {error}
                </Text>
              )}
            </div>
          </Grid>
        </ContainerFluid>
      </StyledWebformContainer>
      <CellulariPrivacyModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  )
}

function RicaricaMessaggio() {
  return (
    <div>
      <StyledRicaricaMessaggioTitle>
        Servizio gratuito di ricarica cellulari 📱
      </StyledRicaricaMessaggioTitle>
      <StyledMessaggioText>
        A2A Life Company. Ogni giorno ci occupiamo di ambiente, acqua energia.
        Mettiamo a disposizione servizi essenziali per rispondere alle esigenze
        degli stili di vita contemporanei, nel rispetto di una sostenibilità di
        lungo periodo.
      </StyledMessaggioText>
    </div>
  )
}

function PostazioneRicarica({
  value,
  onChange,
}: {
  value: string
  onChange: (value: string) => void
}) {
  return (
    <StyledPostazioneRicarica>
      <StyledPostazioneLabel>
        Postazione di ricarica numero:
      </StyledPostazioneLabel>
      <StyledPostazioneInput
        type="number"
        min="0"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="123"
      />
    </StyledPostazioneRicarica>
  )
}
