import { useCpaEntityQuery } from 'hooks/use-cpa-entity'
import { ChatActivate } from '../components/Chat/ChatActivate'
import { RicaricaForm } from '../components/RicaricaForm/RicaricaForm'
import { A2ADataContextProvider } from '../contexts/A2ADataContext'
import { LayoutContextProvider } from '../contexts/LayoutContext'

const Cellulari = () => {
  const chatQuery = useCpaEntityQuery()

  return (
    <A2ADataContextProvider>
      <LayoutContextProvider>
        <RicaricaForm />
        <ChatActivate chatQuery={chatQuery} />
      </LayoutContextProvider>
    </A2ADataContextProvider>
  )
}

export default Cellulari
