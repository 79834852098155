import Modal from 'components/Modale/Modal'
import { ModalProps } from '../../Modale/SearchModal/SearchModal.types'
import {
  StyledPrivacyHTML,
  StyledPrivacyModalWrapper,
} from '../RicaricaForm.style'
import {
  eventDataLayer,
  getGA4PageSezione,
  removeTrailingSlashes,
} from 'commons/utils'
import { useEffect } from 'react'

export const CellulariPrivacyModal = ({ showModal, onClose }: ModalProps) => {
  useEffect(() => {
    if (showModal) {
      eventDataLayer({
        event: 'modale',
        azione: 'modale_impression',
        stream: 'web',
        modale_name: 'privacy',
        position:
          getGA4PageSezione() === 'www' ? 'generic' : getGA4PageSezione(),
        sezione: getGA4PageSezione(),
      })
    }
  }, [showModal])

  return (
    <Modal show={showModal} onClose={onClose} title="Privacy" fullScreen={true}>
      <StyledPrivacyModalWrapper>
        <StyledPrivacyHTML>
          <PrivacyPdfHtml />
        </StyledPrivacyHTML>
      </StyledPrivacyModalWrapper>
    </Modal>
  )
}

function PrivacyPdfHtml() {
  return (
    <>
      <h4>Informativa sul trattamento dei dati personali</h4>
      <p>
        Ai sensi degli artt. 13 e 14 del Regolamento UE 2016/679 del 27 aprile
        2016
        <sup>
          <a href="#fn1">1</a>
        </sup>{' '}
        (di seguito “Regolamento Privacy”), Le forniamo le seguenti informazioni
        in merito al trattamento
        <sup>
          <a href="#fn2">2</a>
        </sup>{' '}
        dei Suoi dati personali.
      </p>
      <h5>1. Chi tratta i dati personali?</h5>
      <p>
        Titolare del trattamento dei dati personali è A2A Energia S.p.A. con
        sede legale a Milano in Corso di Porta Vittoria 4, iscritta nel Registro
        delle Imprese di Milano col seguente numero di codice fiscale e Partita
        IVA 12883420155, R.E.A. Milano n.1592687.
      </p>
      <h5>2. Chi si può contattare?</h5>
      <p>
        Per tutte le questioni relative al trattamento dei dati personali e
        all'esercizio dei suoi diritti, può contattare il Responsabile della
        Protezione dei Dati personali (RPD), all'indirizzo di posta elettronica{' '}
        <a href="mailto:dpo.privacy@a2a.it">dpo.privacy@a2a.it</a>.
      </p>
      <h5>3. Perché vengono trattati i dati personali?</h5>
      <p>
        <table>
          <tr>
            <th>Finalità del trattamento</th>
            <th>Base giuridica del trattamento</th>
          </tr>
          <tr>
            <td>Dare corso alla Sua richiesta</td>
            <td>
              La conclusione, l'esecuzione di un contratto o la risposta a sue
              richieste precontrattuali.
            </td>
          </tr>
          <tr>
            <td>
              Analizzare le sue abitudini e scelte di consumo (profilazione a
              scopo di marketing) allo scopo di offrirle prodotti e servizi
              sempre più in linea con le sue esigenze.
            </td>
            <td rowSpan={2}>Il consenso da lei liberamente espresso.</td>
          </tr>
          <tr>
            <td>
              Effettuare studi e ricerche di mercato, inviare materiale
              pubblicitario e informazioni commerciali (marketing) su prodotti e
              servizi propri, di società del Gruppo A2A o di altre società.
            </td>
          </tr>
        </table>
      </p>
      <h5>4. Quali dati personali sono trattati?</h5>
      <p>
        Sono trattate le seguenti categorie di dati:
        <ul>
          <li>
            dati identificativi e anagrafici (es. nome, cognome, indirizzo,
            ecc…);
          </li>
          <li>
            dati di contatto (es. numeri di telefono, fisso e/o mobile,
            indirizzo email, indirizzo postale, ecc…);
          </li>
          <li>
            altri dati riconducibili alle categorie sopra indicate o da lei
            direttamente conferiti (es. eventuali dati relativi a interessi,
            passioni e stili di vita)
          </li>
        </ul>
      </p>
      <h5>5. Come sono trattati i dati?</h5>
      <p>
        Il trattamento è effettuato dal personale autorizzato nell'espletamento
        delle proprie attività, con o senza l'ausilio di strumenti elettronici,
        secondo principi di liceità e correttezza in modo da tutelare in ogni
        momento la riservatezza e i diritti dell'interessato. Le attività di
        marketing possono essere realizzate attraverso modalità di contatto
        tradizionali, quali posta cartacea e telefonate con operatore, o con
        modalità di contatto automatizzate, quali e-mail, e-mail PEC, SMS, MMS,
        applicazioni web e telefonate senza operatore. Fermo quanto indicato nel
        paragrafo 8, i suoi dati personali potranno essere trattati per le sopra
        citate attività di marketing, previo suo apposito consenso, fino a 24
        mesi dalla cessazione del contratto (se cliente) ovvero fino a 24 mesi
        dalla raccolta dei dati stessi (se prospect, ovvero potenziali clienti)
        La profilazione a scopo di marketing (analisi delle abitudini e delle
        scelte di consumo) avviene mediante un processo automatizzato (tramite
        un sistema informatico e senza l'intervento umano). Fermo quanto
        indicato nel paragrafo 8, i suoi dati personali potranno essere trattati
        per finalità di profilazione anche a livello commerciale, previo suo
        apposito consenso, fino a 48 mesi dalla raccolta dei dati stessi.
      </p>
      <h5>6. A chi sono comunicati i dati personali?</h5>
      <p>
        I suoi dati personali possono essere messi a disposizione di società che
        prestano servizi informatici, social media management, marketing,
        archiviazione che agiranno come Responsabili del trattamento. I suoi
        dati non verranno diffusi (messi a disposizione di soggetti
        indeterminati).
      </p>
      <h5>7. I dati sono trasferiti in paesi terzi?</h5>
      <p>
        I suoi dati personali saranno trattati all'interno dello Spazio
        Economico Europeo (“SEE”). Qualora si rendesse necessario in via
        eccezionale il trasferimento dei suoi dati personali al di fuori dello
        SEE, tale trasferimento avverrà sulla base di una decisione di
        adeguatezza della Commissione Europea, se applicabile, o in presenza
        delle adeguate garanzie richieste dal Regolamento Privacy.
      </p>
      <h5>8. Per quanto tempo i dati sono conservati?</h5>
      <p>
        I suoi dati saranno conservati per il tempo necessario al conseguimento
        delle finalità per le quali sono trattati o per adempiere ad obblighi di
        legge. In particolare: Per i clienti → si rinvia a quanto previsto
        nell'informativa privacy generale sempre disponibile sul sito{' '}
        <a
          href={`${removeTrailingSlashes(
            process.env.SITE_URL
          )}/informativa-privacy`}
          target="_blank"
          rel="noreferrer"
        >
          {removeTrailingSlashes(process.env.SITE_URL)}/informativa-privacy
        </a>{' '}
        e al punto 5 del presente documento. Per i prospect (potenziali clienti)
        → se lei ha conferito almeno un consenso per attività di marketing tra
        quelli richiesti, i suoi dati personali saranno conservati per 24 mesi
        dalla raccolta del dato; se lei ha conferito il consenso per attività di
        profilazione, i suoi dati personali saranno conservati per 48 mesi dalla
        raccolta del dato. In caso di contenzioso, tutti i tempi di
        conservazione sopra indicati, ad eccezione dei dati di interessi,
        passioni e stili di vita, potranno essere estesi fino a 10 anni dalla
        definizione dello stesso.
      </p>
      <h5>9. Quali sono i diritti che può esercitare?</h5>
      <p>
        Lei ha il diritto di chiedere al Titolare del trattamento:
        <ul>
          <li>
            la conferma che sia o meno in corso un trattamento di Suoi dati
            personali e, in tal caso, di ottenerne l'accesso (diritto di
            accesso);
          </li>
          <li>
            la rettifica dei dati personali inesatti o l'integrazione dei dati
            personali incompleti (diritto di rettifica);
          </li>
          <li>
            la cancellazione dei dati stessi se sussiste uno dei motivi previsti
            dal Regolamento Privacy (diritto all'oblio);
          </li>
          <li>
            la limitazione del trattamento quando ricorre una delle ipotesi
            previste dal Regolamento Privacy (diritto di limitazione);
          </li>
          <li>
            di ricevere in un formato strutturato, di uso comune e leggibile da
            dispositivo automatico i dati personali da Lei forniti al Titolare e
            di trasmettere tali dati a un altro Titolare del trattamento
            (diritto alla portabilità);
          </li>
          <li>
            di opporsi in qualsiasi momento al trattamento eseguito per il
            perseguimento di un legittimo interesse del titolare e per finalità
            di marketing, profilazione e geolocalizzazione (diritto di
            opposizione);
          </li>
          <li>
            di revocare l'eventuale consenso al trattamento dei Suoi dati, in
            qualsiasi momento, senza pregiudicare la liceità del trattamento
            basata sul consenso prestato prima della revoca.
          </li>
        </ul>
        Il consenso rilasciato per lo svolgimento di attività di marketing con
        modalità di contatto automatizzate (a titolo esemplificativo sms, mms,
        fax, fonie, e-mail, e-mail pec, applicazioni web) si estende anche alle
        modalità di contatto tradizionali (posta cartacea o chiamata telefonica
        con operatore). Analogamente, l'opposizione allo svolgimento di attività
        di marketing con modalità di contatto automatizzate si estende anche
        alle modalità di contatto tradizionali. Resta salva la sua facoltà di
        fornire il consenso o di esercitare il diritto di opposizione in
        relazione ad una sola delle due modalità di esecuzione delle attività di
        marketing ovvero allo specifico canale di contatto. Con riferimento alle
        decisioni basate unicamente sul trattamento automatizzato, compresa la
        profilazione, ha il diritto di richiedere l'intervento umano, di
        esprimere la propria opinione e di contestare la decisione. Inoltre può
        opporsi alla comunicazione dei suoi dati personali all'Arera per
        l'indagine annuale sulla qualità delle risposte scritte ai reclami e
        alle richieste d'infomazione. Per esercitare i suoi diritti può inviare
        un messaggio alla casella di posta elettronica privacy@a2a.it o una
        comunicazione scritta indirizzata al Titolare del trattamento. Fatto
        salvo ogni altro ricorso amministrativo o giurisdizionale, ha il diritto
        di proporre reclamo all'Autorità Garante per la protezione dei dati
        personali, qualora ritenga che il trattamento che la riguarda violi il
        Regolamento Privacy.
      </p>
      <h5>10. Da quale fonte hanno origine i dati personali?</h5>
      <p>
        Tutti i dati personali necessari all'esecuzione del contratto sono da
        lei conferiti, osservati dal Titolare, ovvero raccolti da altri soggetti
        e il mancato conferimento impedisce di dar corso alla Sua richiesta.
        Tutti i dati personali necessari per le sole finalità di marketing e
        profilazione (es. interessi e passioni) sono da lei conferiti e il
        mancato conferimento non impedisce di dar corso al rapporto
        contrattuale, ma preclude la possibilità di inviarle comunicazioni di
        natura commerciale.
      </p>
      <h5>11. I dati sono sottoposti a decisioni automatizzate?</h5>
      <p>
        I suoi dati non sono sottoposti a decisioni basate unicamente sul
        trattamento automatizzato (attuato da sistemi informatici e che non
        richiede l'intervento umano).
      </p>
      <hr />
      <small id="fn1">
        <sup>1</sup> Regolamento generale sulla protezione dei dati (GDPR).
      </small>
      <small id="fn2">
        <sup>2</sup> Trattamento: qualsiasi operazione o insieme di operazioni,
        compiute con, o senza, l'ausilio di processi automatizzati e applicate a
        dati personali, o a insiemi di dati personali, come la raccolta, la
        registrazione, l'organizzazione, la strutturazione, la conservazione,
        l'adattamento o la modifica, l'estrazione, la consultazione, l'uso, la
        comunicazione mediante trasmissione, diffusione o qualsiasi altra forma
        di messa a disposizione, il raffronto o l'interconnessione, la
        limitazione, la cancellazione o la distruzione.
      </small>
    </>
  )
}
